<template>
  <div>
    <div class="site-section bg-light">
      <h1 class="mb-2" style="text-align: center;">{{$t("pages.about.company.title")}}</h1>
      <div class="container">
        <div class="row justify-content-center">
          <br />
          <div class="row">
            <!-- VISION -->
            <div class="col-md-6 col-lg-6">
              <h2 class="service-heading text-center">{{$t("pages.about.vision.title")}}</h2>
              <p style="text-align: justify; font-size: 16px; ">
                <span class="weight"> {{$t("pages.about.vision.description")}} </span>
                {{$t("pages.about.vision.description_two")}}
                {{$t("pages.about.vision.description_three")}}
                <span class="weight">{{$t("pages.about.vision.description_two_one")}}</span>
                {{$t("pages.about.vision.description_four")}}
                
              </p>
            </div>
            <!-- MISION -->
            <div class="col-md-6 col-lg-6">
              <h2 class="service-heading text-center">{{$t("pages.about.mision.title")}}</h2>
              <p style="text-align: justify; font-size: 16px">
                {{$t("pages.about.mision.description")}} 
                <span class="weight"> {{$t("pages.about.mision.description_two")}}  </span> 
                {{$t("pages.about.mision.description_three")}} 
                <span class="weight"> {{$t("pages.about.mision.description_four")}} </span> 
                {{$t("pages.about.mision.description_five")}}
                <span class="weight">{{$t("pages.about.mision.description_two_one")}}</span>
                {{$t("pages.about.mision.description_two_two")}}
              </p>
            </div>
            <!-- VALUES -->
          </div>
          <div class="col-md-12 col-lg-12">
              <h2 class="service-heading text-center">{{$t("pages.about.values.title")}}</h2>
              <p style="text-align: justify; font-size: 16px">
                <ul class="values">
                  <li><i class="fas fa-check"></i> {{$t("pages.about.values.example_one")}} </li>
                  <li><i class="fas fa-check"></i> {{$t("pages.about.values.example_two")}}</li>
                  <li><i class="fas fa-check"></i> {{$t("pages.about.values.example_three")}} </li>
                  <li><i class="fas fa-check"></i> {{$t("pages.about.values.example_four")}}</li>
                  <li><i class="fas fa-check"></i> {{$t("pages.about.values.example_five")}} </li>
                </ul>
              </p>
            </div>

          <!--
              <div class="col-md-10" data-aos="fade-up" data-aos-delay="100">
                <br>
                <h1 class="mb-2" style="text-align:center; margin-top:1px; padding-botton:2px"> DESA ESTA CONTIGO </h1>
              </div>

              <div class="col-md-10" data-aos="fade-up" data-aos-delay="100">
                <br>
                <p style="text-align:center; padding-bottom:30px; font-size:20px">
                 Soluciones Desa
                </p>
              </div>

              <div class="col-md-3" data-aos="fade-up" data-aos-delay="100">
                <br>
                <h1 class="mb-2" style="text-align: center;font-size:16px"> <i ></i> COMPRA</h1>
              </div>

              <br>

             <div class="row">
              <div class="col-md-6 col-lg-4">
                <a href="#" class="service text-center">
                  <span class="icon flaticon-coin"></span>
                  <h2 class="service-heading">COMPRA</h2>
                </a>
              </div>

              <div class="col-md-6 col-lg-4">
                <a href="#" class="service text-center">
                  <span class="icon flaticon-rent"></span>
                  <h2 class="service-heading">RENTA</h2>
                </a>
              </div>

              <div class="col-md-6 col-lg-4">
                <a href="#" class="service text-center">
                  <span class="icon flaticon-house"></span>
                  <h2 class="service-heading">INVIERTE</h2>
                </a>
              </div>
            </div>
          -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  
  created() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.weight{
  font-weight: 600 !important;
}

.values{
  column-count: 2;
  list-style: none
}

.values i{
  color: #63ae51
}
</style>