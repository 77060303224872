<template>
    <div v-if="agent" class="mb-5">
        <Banner :pageTitle="agent.fullname" image="/images/hero.jpg"/>
        <div class="site-section site-section-sm">
        <div class="container">
            <div class="row">
            <!-- Search
            ==================================================-->
            <SearchForm />
            </div>
        </div>
        </div>
        <div class="container">
        <div class="row">
            <div class="col-lg-4">
            <!--img :src="agent.image" alt="Image" class="img-fluid rounded mb-4"-->
            <AgentCard :agent="agent"/>
            <ContactForm/>
            </div>
            <div class="col-lg-8">
            <div class="row mb-5 text-center">
                <div v-if="agent.slogan" class="w-100 ">
                    <h1 class="my-4 property-offer-type rounded"> "{{agent.slogan}}" </h1>
                </div>
                <div v-if="error == 204" class="container">
                <NotResults  code="204" />
                </div>
                <template v-else>
                <SectionLoader v-if="!properties" class="w-100" />
                <template v-else>
                    <PropertyCard
                    v-for="(item, i) in properties"
                    :key="item.folio"
                    :name="item.folio"
                    :index="i"
                    :item="item"
                    />
                </template>
                </template>
            </div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Banner from "@/components/layout/Banner.vue";
import SectionLoader from '@/components/layout/SectionLoader.vue';
import PropertyCard from "@/components/properties/PropertyCard.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import AgentCard from "@/components/agents/AgentCard.vue";
import NotResults from "@/components/layout/NotFound";
export default {
    components: {
        Banner,
        SectionLoader,
        PropertyCard,
        ContactForm,
        SearchForm,
        AgentCard,
        NotResults
    },
    data(){
        return {
            agentId:''
        }
    },
    computed:{
            ...mapGetters({
                agent:'_getDataAgent',
                properties: "_getPropertiesByAgent",
                error: "_getError",
            }),
            totalProps(){
                return this.properties.length
            }
        },
    /*  computed:{
        agentData(){
            return this.$store.getters._getDataAgent()
        }
    },*/
    created(){
        let agentId=this.$route.params.folio;
        this.$store.dispatch("getAgentInfo", agentId);
        this.$store.dispatch("getPropertiesByAgent", agentId);
    }
};
</script>
