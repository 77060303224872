<template>
  <div>
    <!-- <NotResults v-if="error==204 && !properties" code="204"/> -->
    <div class="container mt-5">
      <SectionLoader v-if="!agents" />
      <div v-else class="row mb-5">
        <AgentCard
          v-for="(item, i) in agents"
          :key="item.folio"
          :name="item.folio"
          :index="i"
          :agent="item"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import SectionLoader from "@/components/layout/SectionLoader.vue";
import AgentCard from "@/components/agents/AgentCard.vue";
import NotResults from '@/components/layout/NotFound';

export default {
  components: {
    SectionLoader,
    AgentCard,
    NotResults

  },
  computed:{
        ...mapGetters({
            agents:'_getTeam',
            error:'_getError',
        }),
    },
    created(){
      this.$store.dispatch('getTeam');
    },
};
</script>
