<template>
    <ul :class="(navStyle=='mobile')?'site-nav-wrap':'site-menu js-clone-nav d-none d-lg-block'">
        <li class="animated-link-dark"><router-link to="/">{{ $t("layout.nav.home") }}</router-link></li>
        <li class="animated-link-dark"><router-link to="/nosotros">{{ $t("layout.nav.about.title") }}</router-link></li>
        <li class="animated-link-dark"><router-link to="/propiedades">{{ $t("layout.nav.properties") }}</router-link></li>
        <li class="animated-link-dark">
            <router-link to="/desarrollos">{{ $t("layout.nav.developments") }}</router-link>
        </li>
        <li class="animated-link-dark"><router-link to="/agentes">{{ $t("layout.nav.agents") }}</router-link></li>
        <li class="animated-link-dark"><router-link to="/inversionistas">{{ $t("layout.nav.investors") }}</router-link></li>
        <li class="animated-link-dark"><router-link to="/contacto">{{ $t("layout.nav.contact") }}</router-link></li>
        <li></li>
        <li class="animated-link-dark">
            <LocaleSwitcher />
        </li>
    </ul>
</template>
<script>
import LocaleSwitcher from "@/components/layout/LocaleSwitcher.vue";
export default {
    props:{
        navStyle:{
            type:String,
            required:true
        }
    },
    components: {
        LocaleSwitcher,
    },
}
</script>